import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BaseConnectSdk } from '../common/baseConnectSdk';
import { Communication } from '../common/communication';
import { Inject } from 'typescript-ioc/es5';
import { OpResult, PayloadData } from '../common/models/operation';
import { OpType, Constants, Status } from '../common/constants';
import { UrlHandler } from '../refresh/urlHandler';
import { Utilities } from '../common/utilities';
import { SsoService } from '../refresh/ssoService';
import { RefreshStatus } from '../refresh/refreshStatus';
import 'rxjs/add/observable/of';
import { MessageCreator } from '../common/messageCreator';

export default class ConnectSdk extends BaseConnectSdk {
    @Inject
    private communication: Communication;
    @Inject
    private urlHandler: UrlHandler;
    @Inject
    private ssoService: SsoService;
    @Inject
    private messageCreator: MessageCreator;
    private queue: { [id: string]: Subject<OpResult> };
    public ready = new Subject<ReadyStatus>();
    private sdkStatus: ReadyStatus;
    constructor(env: string, appId: string, genomeId?: string, lang?: string, nextUrl?: string,
        thirdPartyCookieSupport?: boolean, localLoginExpirationTime?: number, callbackInit?: boolean) {
        super(env, appId, genomeId, lang, nextUrl, thirdPartyCookieSupport, localLoginExpirationTime);
        this.configService.config.sender = Constants.baseUrls.find(f => f.key === this.configService.config.environment).value;
        this.queue = {};
        this.sdkStatus = <ReadyStatus>{ sdkReady: false, thirdPartyCookiesEnabled: true };

        if (!callbackInit) {
            this.communication.setupCommunicationEvent();
            this.communication.getIncomigMessages().subscribe((message) => this.handleMessages(message));
            this.communication.setupCommunicationIframe();
        }
    }
    private handleMessages(event: Event): void {
        const message = event as MessageEvent;
        if (message != null && message.data != null) {
            const transferData = message.data as OpResult;
            const sdkReadyEvent = this.checkAndSetIfItsReadyEvent(transferData);
            if (sdkReadyEvent.sdkReady) {
                this.sdkStatus = sdkReadyEvent;
                this.ready.next(sdkReadyEvent);
                this.ready.complete();
            }
            if (this.queue[transferData.opId]) { //check for messages in queue and pass the response to the subscribers
                this.queue[transferData.opId].next(transferData);
            }
        }
    }
    public getTicketFromSsoId(callback: (result: any) => void): void {
        const refreshStatus = Utilities.getQsParam('refreshStatus');
        const ssoId = decodeURIComponent(Utilities.getQsParamWithAllChars('connectSsoId'));
        this.handleSsoStuff(refreshStatus as RefreshStatus, ssoId, callback);
    }

    private handleSsoStuff(refreshStatus: RefreshStatus, ssoId: string, callback: (result: any) => void): void {
        this.ls.removeItem(Constants.localErrorKey);
        this.ls.removeItem(Constants.loginDataKey);
        this.ls.removeItem(Constants.SsoCallBackStatusKey);

        if (refreshStatus === RefreshStatus.ok && ssoId) {
            this.ssoService.get(ssoId).subscribe((success) => {
                const result = {
                    status: Status.ok,
                    opId: 'local',
                    payload: {
                        sessionId: success.sessionId,
                        ticket: success.ticket,
                        userId: success.userId
                    } as PayloadData
                } as OpResult;
                this.ls.setItem(Constants.loginDataKey, result, this.configService.config.localLoginExpirationTime);
                this.ls.setItem(Constants.SsoCallBackStatusKey, { calledBack: true }, this.configService.config.localLoginExpirationTime);
                callback(result);
            }, () => {
                const err = this.messageCreator.ssoRetrieveError();
                this.ls.setItem(Constants.localErrorKey, err, this.configService.config.localLoginExpirationTime);
                this.ls.setItem(Constants.SsoCallBackStatusKey, { calledBack: true }, this.configService.config.localLoginExpirationTime);
                callback(err);
            });
        } else {
            const errByStatus = this.messageCreator.getErrorByStatus(refreshStatus)
            this.ls.setItem(Constants.localErrorKey, errByStatus, this.configService.config.localLoginExpirationTime);
            this.ls.setItem(Constants.SsoCallBackStatusKey, { calledBack: true }, this.configService.config.localLoginExpirationTime);
            callback(errByStatus);
        }
    }
    public refreshWithThirdpartyDisabled(): void {
        let url = Constants.baseUrls
            .find(f => f.key === this.configService.config.environment).value + '/refresh.html';
        const fullUrl = this.urlHandler.appendParams(url, [
            { key: 'env', value: this.configService.config.environment },
            { key: 'appId', value: this.configService.config.appId },
            { key: 'genomeId', value: this.configService.config.genomeId },
            { key: 'nextUrl', value: encodeURIComponent(this.configService.config.nextUrl) }
        ]);
        window.top.location.href = fullUrl;
    }

    public logOffWithThirdPartyDisabled() {
        let url = Constants.baseUrls
            .find(f => f.key === this.configService.config.environment).value + '/refresh.html';
        const fullUrl = this.urlHandler.appendParams(url, [
            { key: 'env', value: this.configService.config.environment },
            { key: 'appId', value: this.configService.config.appId },
            { key: 'genomeId', value: this.configService.config.genomeId },
            { key: 'nextUrl', value: encodeURIComponent(this.configService.config.nextUrl) },
            { key: 'logOffUser', value: 'true' }
        ]);
        window.top.location.href = fullUrl;
    }

    public checkAndSetIfItsReadyEvent(transferData: OpResult): ReadyStatus {
        switch (true) {
            case transferData.opId !== 'everythingSet':
                return { sdkReady: false, thirdPartyCookiesEnabled: false, message: '' };
            case transferData.status === Status.ready:
                return { sdkReady: true, thirdPartyCookiesEnabled: true, message: '' };
            case transferData.status === Status.thirdPartyCookiesDisabled:
                return { sdkReady: true, thirdPartyCookiesEnabled: false, message: '' }
            default: return { sdkReady: false, thirdPartyCookiesEnabled: false, message: '' }
        }
    }
    public logout(ticket: string, sessionId: string): Observable<OpResult> {
        if (!this.sdkStatus.thirdPartyCookiesEnabled) {
            return this.logoutWithOutThirdParties();
        }
        return this.logOutWithThirdParties(ticket, sessionId);
    }
    private logoutWithOutThirdParties(): Observable<OpResult> {
        this.ls.removeItem(Constants.loginDataKey);
        this.ls.removeItem(Constants.localErrorKey);
        this.ls.removeItem(Constants.SsoCallBackStatusKey);
        if (this.configService.config.thirdPartyCookieSupport) {
            this.logOffWithThirdPartyDisabled();
        }
        return Observable.of({
            status: Status.ok,
            opId: 'local',
        } as OpResult);
    }
    private logOutWithThirdParties(ticket: string, sessionId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.Logout);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            type: OpType.Logout,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
            payload: { ticket: ticket, sessionId: sessionId }
        }, this.configService.config.sender);
        return this.queue[id];
    }
    public triggerEmailActivation(ticket: string, sessionId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.TriggerEmailActivation);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            type: OpType.TriggerEmailActivation,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
            payload: { ticket: ticket, sessionId: sessionId }
        }, this.configService.config.sender);
        return this.queue[id];
    }
    private getTicketWithThirdParties(): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetTicket);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
            type: OpType.GetTicket
        }, this.configService.config.sender);
        return this.queue[id];
    }
    private getCookiesDisabledNotification(): Observable<OpResult> {
        return Observable.of({
            opId: 'local',
            errorMessage: 'thirdPartyCookies are disabled, use the sdk option to be able to retrieve the data',
            status: Status.thirdPartyCookiesDisabled
        } as OpResult);
    }
    private getTicketWithoutThirdParties(): Observable<OpResult> {
        const localData = this.ls.getItem<any>(Constants.loginDataKey);
        if (localData) {
            return Observable.of(localData);
        }
        const localError = this.ls.getItem<any>(Constants.localErrorKey);
        this.ls.removeItem(Constants.SsoCallBackStatusKey);
        if (localError) {
            return Observable.of(localError);
        }
        return Observable.of(this.messageCreator.genericError());
    }

    public getTicket(): Observable<OpResult> {
        const ssoStatusSet = this.ls.getItem<any>(Constants.SsoCallBackStatusKey);
        let calledBack = false;
        if (ssoStatusSet != null) {
            calledBack = ssoStatusSet.calledBack;
        }
        switch (true) {
            case !this.configService.config.thirdPartyCookieSupport && !this.sdkStatus.thirdPartyCookiesEnabled:
                return this.getCookiesDisabledNotification();
            case this.configService.config.thirdPartyCookieSupport && !this.sdkStatus.thirdPartyCookiesEnabled && calledBack: //and if the callback has been performed
                return this.getTicketWithoutThirdParties();
            case this.configService.config.thirdPartyCookieSupport && !this.sdkStatus.thirdPartyCookiesEnabled:
                this.refreshWithThirdpartyDisabled();
                break;
            default: return this.getTicketWithThirdParties();
        }
    }
    public getUserStatus(ticket: string, sessionId: string, userId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetUserStatus);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            type: OpType.GetUserStatus,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
            payload: { ticket: ticket, sessionId: sessionId, userId: userId }
        }, this.configService.config.sender);
        return this.queue[id];
    }
    public getProfiles(ticket: string, sessionId: string, userId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetProfiles);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            type: OpType.GetProfiles,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
            payload: { ticket: ticket, sessionId: sessionId, userId: userId }
        }, this.configService.config.sender);
        return this.queue[id];
    }    
    public getThirdPartyStatus(): Observable<OpResult>{
        const id = Communication.generateUniqueId(OpType.GetThirdPartyStatus);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            type: OpType.GetThirdPartyStatus,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport
        }, this.configService.config.sender);
        return this.queue[id];
    }

    public setTicket(ticket: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.SetTicket);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            type: OpType.SetTicket,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
            payload: { ticket: ticket }
        }, this.configService.config.sender);
        return this.queue[id];
    }
    public triggerEmailValidation(ticket: string, sessionId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.TriggerEmailActivation);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild({
            opId: id,
            type: OpType.TriggerEmailActivation,
            thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
            payload: { ticket: ticket, sessionId: sessionId }
        }, this.configService.config.sender);
        return this.queue[id];
    }
}


export function init(config: Config): void {
    if ((window as any).Connect.sdk === null || (window as any).Connect.sdk === undefined) {
        (window as any).Connect.sdk = new Subject<ConnectSdk>();
    }
    if ((window as any).Connect._sdk === undefined || (window as any).Connect._sdk === null) {
        const sdk = new ConnectSdk(config.env, config.appId, config.genomeId, config.lang, config.nextUrl, config.thirdPartyCookiesSupport, config.localLoginExpirationMinutes);
        sdk.ready.subscribe(() => {
            (window as any).Connect._sdk = sdk;
            (window as any).Connect.sdk.next((window as any).Connect._sdk);
        });
    } else {
        (window as any).Connect.sdk.next((window as any).Connect._sdk);
    }
}
export interface Config {
    env: string;
    appId: string;
    genomeId?: string;
    lang?: string;
    nextUrl?: string;
    thirdPartyCookiesSupport?: boolean;
    localLoginExpirationMinutes?: number;
}
export interface ReadyStatus {
    sdkReady: boolean;
    thirdPartyCookiesEnabled: boolean;
    message: string;
}