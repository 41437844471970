import { Singleton, Inject } from "typescript-ioc/es5";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/operator/map';
import { ConfigService } from '../common/configService';
import { Constants } from "../common/constants";
@Singleton
export class SsoService {
    @Inject
    private configService: ConfigService;
    private connectBaseUrl: string;
    constructor() {
        this.connectBaseUrl = Constants.baseApiUrls.find(
            (f) => f.key === this.configService.config.environment).value;
    }
    public set = (ticket: string, sessionId?: string, userId?: string): Observable<SsoResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            'Authorization': 'Ubi_v1 t=' + ticket,
            'Ubi-AppId': this.configService.config.appId,
            'UserId': userId,
            'SessionId': sessionId
        };
        return Observable.ajax.post(this.connectBaseUrl + '/api/sso/v1/refresh',
            {}, headers).map((s) => s.response as SsoResponse);
    }
    public setSso = (ticket: string): Observable<SsoResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            'Authorization': 'Ubi_v1 t=' + ticket,
            'Ubi-AppId': this.configService.config.appId
        };
        return Observable.ajax.post(this.connectBaseUrl + '/api/sso/v1/entry',
            {}, headers).map((s) => s.response as SsoResponse);
    }
    public get = (ssoId: string): Observable<SsoCompleteResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            'Ubi-SsoId': ssoId,
            'Ubi-AppId': this.configService.config.appId
        };
        return Observable.ajax.get(this.connectBaseUrl + '/api/sso/v1/refresh', headers)
            .map((s) => s.response as SsoCompleteResponse);

    }
    public getSso = (ssoId: string): Observable<SsoCompleteResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            'Ubi-SsoId': ssoId,
            'Ubi-AppId': this.configService.config.appId
        };
        return Observable.ajax.get(this.connectBaseUrl + '/api/sso/v1/entry', headers)
            .map((s) => s.response as SsoCompleteResponse);
    }
}
export interface SsoResponse {
    ssoId: string;
}
export interface SsoCompleteResponse {
    ticket: string;
    sessionId: string;
    userId: string;
}

