import { ConfigService } from './configService';
import { Inject, Singleton } from 'typescript-ioc/es5';

@Singleton
export class LocalStorage {
    @Inject
    private configService: ConfigService;

    public getItem<T>(key: string, message?: string): T {
        try {
            const item = JSON.parse(localStorage.getItem((this.configService.config.environment + key).trim())) as T;
            // if (Date.parse((item as any).expirationDate) <= (new Date()).getTime()) {
            if (((item as any).expirationDate as number) <= (new Date()).getTime()) {
                localStorage.removeItem(this.configService.config.environment + key);
                return null;
            }
            return item;
        } catch (e) {
            return null;
        }
    }

    public setItem<T>(key: string, item: T, expirationMinutes: number = 175): void {
        try {
            const date = new Date();
            date.setTime(date.getTime() + expirationMinutes * 60 * 1000); // converting minutes to ms
            (item as any).expirationDate = date.getTime();
            localStorage.setItem(this.configService.config.environment + key, JSON.stringify(item));
        } catch{
        }
    }

    public removeItem(key: string): void {
        localStorage.removeItem(this.configService.config.environment + key);
    }

    public getItemWithoutExpiration<T>(key: string, message?: string): T {
        try {
            const item = JSON.parse(localStorage.getItem((this.configService.config.environment + key).trim())) as T;
            return item;
        } catch (e) {
            return null;
        }
    }

    public setItemWithoutExpiration<T>(key: string, item: T): void {
        try {
            localStorage.setItem(this.configService.config.environment + key, JSON.stringify(item));
        } catch{
        }
    }
}
