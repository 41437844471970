import { ConfigModel } from './models/configModel';
import { LocalStorage } from './localStorage';
import { Constants } from './constants';
import { Inject, AutoWired } from 'typescript-ioc/es5';
import { ConfigService } from './configService';
import { SessionStorage } from './sessionStorage';
@AutoWired
export class BaseConnectSdk {
    @Inject
    public configService: ConfigService;
    @Inject
    public ls: LocalStorage;

    constructor(environment: string, appId: string, genomeId?: string, lang?: string, nextUrl?: string, thirdPartySupport?: boolean, localLoginExpirationTime?: number) {
        this.setAllConfig(environment, appId, genomeId, lang, nextUrl, thirdPartySupport, localLoginExpirationTime);
    }
    private setAllConfig = (environment?: string,
        appId?: string,
        genomeId?: string,
        lang?: string,
        nextUrl?: string,
        thirdPartySupport?: boolean,
        localLoginExpirationTime?: number): void => {
        let validEnv = Constants.Environments[0];
        if (environment) {
            const possibleEnv = Constants.Environments.find(f => f === environment.toUpperCase());
            validEnv = possibleEnv || validEnv;
        }
        this.configService.config = new ConfigModel({
            environment: validEnv,
            appId: appId || Constants.defaultAppId,
            genomeId: genomeId || '',
            nextUrl: decodeURIComponent(nextUrl) || Constants.defaultNextUrl,
            lang: lang || Constants.defaultLang,
            thirdPartyCookieSupport: thirdPartySupport || false,
            localLoginExpirationTime: localLoginExpirationTime || 10
        });
    }
}
