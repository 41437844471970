import { Singleton } from "typescript-ioc/es5";
@Singleton
export class UrlHandler {
    constructor() { };
    public appendParams = (url: string, urlParams: QsParam[]): string => {
        const qsPosition = url.indexOf('?');
        if (qsPosition < 0) {
            url += '?' + this.getParamsString(urlParams);
        } else {
            url += '&' + this.getParamsString(urlParams);

        }
        return url.substring(0, url.length - 1);
    }
    public getParamsString = (urlParams: QsParam[]): string => {
        let returnVal = '';
        urlParams.forEach((i) => {
            if (i.value) {
                returnVal += i.key + '=' + i.value + '&';
            }
        });
        return returnVal;
    }
}

export interface QsParam {
    key: string;
    value?: string;
}
