export class Constants {
    public static Environments = ['DEV','TEST','UAT', 'PROD','CERT2'];
    public static baseUrls = [
        { key: 'DEV', value: 'https://web.archive.org/web/20200619152314/https://localhost.ubisoft.com' },
        { key: 'UAT', value: 'https://web.archive.org/web/20200619152314/https://uat-connect.ubisoft.com' },
        { key: 'CERT2', value: 'https://web.archive.org/web/20200619152314/https://cert2-connect.ubisoft.com' },
        { key: 'PROD', value: 'https://web.archive.org/web/20200619152314/https://connect.ubisoft.com' },
        { key: 'TEST', value: 'https://web.archive.org/web/20200619152314/https://test-connect.ubisoft.com' }
    ];
    public static baseApiUrls = [
        { key: 'DEV', value: 'https://web.archive.org/web/20200619152314/https://localhost.ubisoft.com:50000' },
        { key: 'UAT', value: 'https://web.archive.org/web/20200619152314/https://uat-connect.ubisoft.com' },
        { key: 'CERT2', value: 'https://web.archive.org/web/20200619152314/https://cert2-connect.ubisoft.com' },
        { key: 'PROD', value: 'https://web.archive.org/web/20200619152314/https://connect.ubisoft.com' },
        { key: 'TEST', value: 'https://web.archive.org/web/20200619152314/https://test-connect.ubisoft.com' }
    ];

    public static ubiservicesBaseUrls = [
        { key: 'DEV', value: 'https://web.archive.org/web/20200619152314/https://uat-public-ubiservices.ubi.com' },
        { key: 'UAT', value: 'https://web.archive.org/web/20200619152314/https://uat-public-ubiservices.ubi.com' },
        { key: 'CERT2', value: 'https://web.archive.org/web/20200619152314/https://cert2-public-ubiservices.ubi.com' },
        { key: 'PROD', value: 'https://web.archive.org/web/20200619152314/https://public-ubiservices.ubi.com' },
        { key: 'TEST', value: 'https://web.archive.org/web/20200619152314/https://uat-public-ubiservices.ubi.com' }
    ];

    public static cookieDomains = [
        { key: 'DEV', value: 'ubisoft.com' },
        { key: 'UAT', value: 'ubisoft.com' },
        { key: 'CERT2', value: 'ubisoft.com' },
        { key: 'PROD', value: 'ubisoft.com' }
    ];

    public static defaultAppId = '412802ED-8163-4642-A931-8299F209FECB';
    public static defaultNextUrl = 'https://web.archive.org/web/20200619152314/https://ubisoft.com';
    public static defaultLang = 'en-US';

    public static expiration29Days = 20160;
    public static expiration6Months = 262974;
    public static loginDataKey = 'loginData';
    public static lastProfileKey = 'lastProfile';
    public static rememberKey = 'rememberMe';
    public static rememberDeviceKey = 'rememberDevice';
    public static localErrorKey = 'errorRefreshData';
    public static loginDataExpiration = 177;
    public static mutexKey = 'lockRequestsKey';

    public static SsoCallBackStatusKey = "ssoCallBackStatusKey"
}
export enum Status {
    ready = 'ready',
    error = 'error',
    ok = 'ok',
    invalidDomain = 'invalidDomain',
    ageGateFailed = 'agaGateFailed',
    twoFaFailed = '2faFailed',
    invalidConfig = 'invalidConfig',
    missingTicket = 'missingTicket',
    missingSessionId = 'missingSessionId',
    missingUserId = 'missingUserId',
    thirdPartyCookiesDisabled = 'thirdPartyCookiesDisabled',
    ssoRetrieveError = 'ssoRetrieveError',
    noLoginData = 'noLoginData'

}
export enum OpType {
    GetTicket = 'GetTicket',
    SetTicket = 'SetTicket',
    GetProfiles = 'GetProfiles',
    GetUserStatus = 'GetUserStatus',
    TriggerEmailActivation = 'TriggerEmailActivation',
    CheckThirdPartyStatus = 'CheckThirdPartyStatus',
    Logout = 'Logout',
    GetThirdPartyStatus = 'GetThirdPartyStatus'

}
export interface KeyValuePairString {
    key: string;
    value: string;
}


