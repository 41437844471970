export enum RefreshStatus {
    ok = 'ok',
    noLoginData = 'noLoginData',
    ssoError = 'ssoError',
    ageGate = 'ageGate',
    userError = 'userError',
    invalidConfig = 'invalidConfig',
    invalidTwoFaStatus = 'invalidTwoFaStatus'
}

