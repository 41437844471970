export class Utilities {
    public static getQsParam(paramName: string, url?: string): string {
        if (!url) {
            url = window.location.href;
        }
        paramName = paramName.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    public static getQsParamWithAllChars(paramName: string, url?: string) {
        const rez = Utilities.getQsParam(paramName, url);
        if (rez == null || rez === '') {
            return rez;
        }
        return rez.replace(' ', '+');
    }
    public static thirdPartyEnabled = (): boolean => {
        try {
            localStorage.setItem('chkThird', JSON.stringify(new Date().getTime()));
            const val = localStorage.getItem('checkForBadBrowser');
            if (!val && Utilities.isSafari()) {
                return false;
            }
            return true;
        } catch {
            return false;
        }
    }
    public static isSafari = (): boolean => {
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;
    }

    public static getObjectWithLowercaseKeys = (obj: any) => {
        var keys = Object.keys(obj);
        var n = keys.length;
        var newobj: any = {};

        while (n--) {
            var key = keys[n];
            newobj[key.toLowerCase()] = obj[key];
        }

        return newobj;
    }
}

